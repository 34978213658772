<template>
  <div class="row">
    <div class="col-md-6">
      <div class="row">
        <div class="col-6 col-md-2 d-flex align-items-center">
          <router-link :to="{ name: isLeaseProduct ? 'leaseproductpage' : 'productpage', params: { slug: product.slug }}">
            <img v-if="!product.primary_thumb_small" src="@/assets/images/product-photo-coming-soon-small.jpg" class="product-card-image d-none d-md-inline" alt="">
            <div v-else>
              <img width="50" :src="imageUrl" class="product-card-image d-none d-md-inline" alt="">
              <img :src="mobileImageUrl" class="product-card-image d-md-none w-100" alt="">
            </div>
          </router-link>
        </div>
        <div class="col-6 col-md-6">
          <div>{{ getProductName() }}</div>
          <div v-if="variant" class="in-stock">
            <div class="price" v-if="variant.sku">SKU: {{variant.sku}}</div>
          </div>
          <div class="price" v-else-if="product.ext_product_id">SKU: {{product.ext_product_id}}</div>
          <div class="price" v-if="isLeaseProduct">Lease product</div>
          <div v-else-if="product.stock_management">
            <div v-if="variant" class="in-stock">
              In stock: {{ variant.stock_quantity }}
            </div>
          </div>
          <div class="price" v-else>
            Produced after order
          </div>
          <div v-if="!isLeaseProduct" class="d-md-none d-flex align-items-center">{{ product.price + $store.getters.currencyStr + " per item" }}</div>
        </div>
        <div class="col-md-4 d-none d-md-flex align-items-center">{{ isLeaseProduct ? '-' : product.price + $store.getters.currencyStr }}</div>
      </div>
    </div>
    <div class="col-md-2 d-flex align-items-center">
      <div class="pl-md-2" v-if="isLeaseProduct">
        <div class="d-none d-md-block">{{ cartItem.count }}</div>
        <div class="d-block d-md-none">
          <div class="d-block d-md-none mt-3">Quantity</div>
          <input class="inputfield" type="number" min="1" required="" v-model.number="cartItem.count" disabled>
        </div>
      </div>
      <div class="w-100" v-else>
        <div class="d-block d-md-none mt-3">Quantity</div>
        <input class="inputfield" type="number" min="1" required="" v-on:blur="editCount(product.id, cartItem.variantId)" v-model.number="count">
        <div v-if="count_nok" class="form-field-error">Quantity can't be 0.</div>
      </div>
    </div>
    <div class="col-md-1 d-flex align-items-center">
      <span class="d-block d-md-none mr-1 my-4">Subtotal</span>
      <span v-if="!isLeaseProduct" class="d-block d-md-none price">{{ total+$store.getters.currencyStr }}</span>
      <span v-else class="d-block d-md-none price">0{{$store.getters.currencyStr}}</span>
      <span class="d-none d-md-block">{{ isLeaseProduct ? '-' : total+$store.getters.currencyStr }}</span>
    </div>
    <div class="col-md-3 remove-item d-flex align-items-center" >
      <button class="btn btn-lg btn-outline-primary btn-block mt-0" @click="removeFromCart()">Remove from cart</button>
    </div>
    <div class="col-md-12"><hr /></div>
  </div>
</template>

<script>

export default {
  name: 'ProductRow',
  props: {
    cartItem: Object,
  },
  data() {
    return {
      count: this.cartItem.count,
      count_nok: false
    }
  },
  watch: {
    count: function() {
      if(this.count < 1){
        this.count_nok = true;
      } else {
        this.count_nok = false;
      }
    }
  },
  computed: {
    product(){
      if(this.$store.state.cartProductObjects.length > 0 && !this.isLeaseProduct){
        return this.$store.state.cartProductObjects.find(p => p.id == this.cartItem.productId)
      } else if(this.$store.state.cartLeaseProductObjects.length > 0 && this.isLeaseProduct){
        return this.$store.state.cartLeaseProductObjects.find(p => p.id == this.cartItem.leaseProductId)
      } else {
        return {}
      }
    },
    variant(){
      if(this.cartItem.variantId && this.product.variants){
        const variant = this.product.variants.find(v => v.id == this.cartItem.variantId)
        if(variant){
          return variant;
        } else {
          return null;
        }
      }
      return null;
    },
    total(){
      return (this.product.price * this.cartItem.count).toFixed(2);
    },
    imageUrl(){
      const path = this.product.primary_thumb_small;
      if(path && path.startsWith(process.env.VUE_APP_IMAGE_HOST)){
        return path;
      } else {
        return process.env.VUE_APP_IMAGE_HOST + path;
      }
    },
    mobileImageUrl(){
      const path = this.product.primary_thumb_big;
      if(path && path.startsWith(process.env.VUE_APP_IMAGE_HOST)){
        return path;
      } else {
        return process.env.VUE_APP_IMAGE_HOST + path;
      }
    },
    isLeaseProduct(){
      return this.cartItem.hasOwnProperty('leaseProductId')
    }
  },
  methods: {
    removeFromCart(){
      if(this.isLeaseProduct){
        const item = {leaseProductId: this.cartItem.leaseProductId};
        this.$store.commit('removeLeaseProductFromCart', item)
      } else {
        const item = {productId: this.product.id, variantId: this.cartItem.variantId};
        this.$store.commit('removeProductFromCart', item)
      }
    },
    editCount(productId, variantId){
      if(this.count > 0){
        const item = {productId: productId, count: this.count, variantId: variantId};
        this.$store.commit('editProductCount', item);
      } else {
        this.count = 1;
        const item = {productId: productId, count: this.count, variantId: variantId};
        this.$store.commit('editProductCount', item);
      }
    },
    getProductName(){
      if(this.variant && this.product.variants.length > 1){
        return this.product.name + " - " + this.variant.name;
      } else {
        return this.product.name;
      }
    }
  }
}
</script>