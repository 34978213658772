<template>
  <div>
    <TopNav v-bind:showLogin=false v-bind:showStores=true />
    <SidebarNav />
    <div v-if="!productsFetched">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="container">
      <div class="row">
        <div class="col-md-12 mt-md-5 mb-3">
          <h1 class="mt-4">Your cart</h1>
        </div>
      </div>
      <div class="row d-none d-md-flex">
        <div class="col-md-4">Product</div>
        <div class="col-md-2">Price per item</div>
        <div class="col-md-2">Quantity</div>
        <div class="col-md-1">Subtotal</div>
        <div class="col-md-3"></div>
      </div>
      <div class="row d-none d-md-flex">
        <div class="col-12">
          <hr />
        </div>
      </div>
      <div v-for="cartProduct in cartProducts" v-bind:key="cartProduct.productId + '-' + cartProduct.leaseProductId + '-' + cartProduct.variantId">
        <ProductRow v-bind:cartItem=cartProduct />
      </div>
      <div v-if="cartProducts.length == 0">
        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="col-md-3">
                <div class="emptypic"></div>
              </div>
              <div class="col-md-9">
                There are no products in your cart.
              </div>
            </div>
          </div>
          <div class="col-md-12"><hr /></div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-2 price d-flex align-items-center">Total</div>
        <div class="col-md-2 d-flex align-items-center">{{$store.getters.cartItemCount}} items</div>
        <div class="col-md-1 d-flex align-items-center">{{ cartTotal }}{{$store.getters.currencyStr}}</div>
        <div class="col-md-3">
          <button class="btn btn-lg btn-primary btn-block mt-3 mt-md-0" v-on:click="deliDetails()" v-if="cartProducts.length > 0">Continue to delivery details</button>
        </div>
      </div>

    </div>
    <Footer />
  </div>
</template>

<script>
import TopNav from '@/modules/general/components/TopNav.vue'
import Footer from '@/modules/general/components/Footer.vue'
import ProductRow from '../components/ProductRow'
import SidebarNav from "@/modules/general/components/sidebar/SidebarNav.vue";


export default {
  name: 'Cart',
  components: {
    TopNav,
    Footer,
    ProductRow,
    SidebarNav
  },
  data() {
    return {
      updatingCart: false,
    }
  },
  mounted(){
    // fetch all the product in the cart
    this.fetchCartProducts()
  },
  watch: {
    activeStore: function (newVal, oldVal) {
      if(newVal && !oldVal){
        if(!this.productsFetched){
          // Got activeStore and products not fetched
          this.fetchCartProducts();
        }
      }
    }
  },
  computed: {
    cartProducts() {
      const items = this.$store.getters.cartItems;
      if(this.$store.getters.cartLeaseItems && this.$store.getters.cartLeaseItems.length > 0){
        return items.concat(this.$store.getters.cartLeaseItems);
      }
      return items;
    },
    cartTotal(){
      return this.$store.getters.cartTotal;
    },
    activeStore(){
      return this.$store.state.activeStore ? true : false;
    },
    productsFetched(){
      return this.$store.state.cartProductObjectsFetched;
    }
  },
  methods: {
    fetchCartProducts(){
      if(this.$store.state.activeStore){
        this.$store.dispatch('fetchCartProductObjects');
      }
    },
    deliDetails(){
      this.$store.commit('clearSelectedRates')
      if(this.cartStock() && this.checkItemCounts()){
        this.$router.push({ name: 'deliverydetails' })
      }
      return;
    },
    cartStock(){
      let stockOk = true;
      for(const cartProduct of this.cartProducts){
        // Check if product has variantid and can have stock management
        if(cartProduct.variantId){
          // get product
          const prod = this.$store.state.cartProductObjects.find(p => p.id == cartProduct.productId);
          // check stock management
          if(prod && prod.stock_management){
            const variant = prod.variants.find(v => v.id == cartProduct.variantId);
            if(cartProduct.count > variant.stock_quantity){
              stockOk = false;
              let errMsg = "Not enough products in stock for " + prod.name + ": " + variant.name +
                ". We only have " + variant.stock_quantity + " in stock.";
              this.$toastr.e(
                errMsg
              );
            }
          }
        }
      }
      return stockOk;
    },
    checkItemCounts(){
      // get counts per productId, exclude leased products
      const counts = this.cartProducts
        .filter(cp => cp.hasOwnProperty('productId'))
        .reduce(function (total, obj) {
          let key = obj['productId'];
          if (!total[key]) {
              total[key] = 0;
          }
          total[key] += obj['count'];
          return total;
      }, {});
      // check order unit  and moq per product
      let unitsOk = true;
      for (let [key, value] of Object.entries(counts)) {
        const prod = this.$store.state.cartProductObjects.find(p => p.id == key);
        if(prod.moq && prod.moq > value){
          let errMsg =  "Minimum order quantity for "+ prod.name + " is " + prod.moq + ". Total is now " + value;
          this.$toastr.e(
            errMsg
          );
          unitsOk = false;
        }else if(prod.order_unit && value % prod.order_unit != 0){
          let errMsg = prod.name + " can be only delivered in packaging units of " + prod.order_unit + ". Total is now " + value;
          this.$toastr.e(
            errMsg
          );
          unitsOk = false;
        }
      }
      return unitsOk;
    }
  }
}
</script>
